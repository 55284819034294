window._config = {
  site_name: 'brokenwrenches.com',
  image_retry_interval_ms: 500,
  cookie_refresh_interval_ms: 60*60*3*1000
};

if (window.location.href.indexOf('http://localhost') > -1){
  window._config.stage = 'local';
  window._config.cognito = {
    userPoolId: 'us-west-2_jvJYZTDdX',
    userPoolClientId: '7edq5jba2gkjr8qfs0liop0m42',
    region: 'us-west-2'
  };

} else if (window.location.href.indexOf(`https://dev.${window._config.site_name}/`) > -1){
  window._config.stage = 'dev';
  window._config.cognito = {
    userPoolId: 'us-west-2_pAPsO9U9o',
    userPoolClientId: '1k4e69dah945rvg1qtc4ntl0lp',
    region: 'us-west-2'
  };

} else {
  window._config.stage = 'prod';
  window._config.cognito = {
    userPoolId: 'us-west-2_8i3MxM2X2',
    userPoolClientId: '5c2ema5o3a3lksn0uejb1eid6a',
    region: 'us-west-2'
  };
}

if (window._config.stage == 'prod'){
  window._config.apiUrl = `https://api.${window._config.site_name}`;
  window._config.imageUrl = `https://images.${window._config.site_name}`;

} else {
  window._config.apiUrl = `https://${window._config.stage}.api.${window._config.site_name}`;
  window._config.imageUrl = `https://${window._config.stage}.images.${window._config.site_name}`;
}
